import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const GoogleTranslate = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <Button onClick={() => changeLanguage('vi')} size="small" color="success" disabled={i18n.language === 'vi'}>
        Tiếng Việt (越南语)
      </Button>
      <Button onClick={() => changeLanguage('cn')} size="small" color="success" disabled={i18n.language === 'cn'}>
        中国人 (Tiếng Trung)
      </Button>
    </div>
  );
}

// const GoogleTranslate = () => {
//   useEffect(() => {
//     const googleTranslateScript = document.createElement('script');
//     googleTranslateScript.type = 'text/javascript';
//     googleTranslateScript.innerHTML = `
//       function googleTranslateElementInit() {
//         new google.translate.TranslateElement({
//           pageLanguage: 'vi',
//           includedLanguages: 'vi,en,zh-CN',
//           layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
//           autoDisplay: false
//         }, 'google-translate-element');
//       }
//     `;
//     document.head.appendChild(googleTranslateScript);

//     const googleTranslateApiScript = document.createElement('script');
//     googleTranslateApiScript.type = 'text/javascript';
//     googleTranslateApiScript.src =
//       '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//     googleTranslateApiScript.async = true;
//     document.head.appendChild(googleTranslateApiScript);
//   }, []);

//   return <div id="google-translate-element" />;
// };

export default GoogleTranslate;
