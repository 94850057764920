import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { toast, ToastContainer } from "react-toastify";
import {
	Box,
	Button,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Pagination,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function Add() {
	const { t } = useTranslation();
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [data, setData] = useState(null);
	const [load, setLoad] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (load === false) {
			axios.get(`${process.env.REACT_APP_API_URL}/payment/nap`, {}).then((res) => {
				setData(res.data.data);
				localStorage.setItem("data", JSON.stringify(res.data.data));
				setLoad(true);
			});
		}
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					return row.user.iduser
						.toString()

						.includes(searchedVal);
				}
			);
			setData(filteredRows);
		} else {
			setData(JSON.parse(localStorage.getItem("data")));
		}
	};
	const [pages, setPage] = useState(1);
	const [searched, setSearched] = useState("");
	const handleChange = (e, page) => {
		setPage(page);
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Box
							component="main"
							sx={{
								flexGrow: 1,
								py: 1,
							}}
						>
							{data && (
								<Container maxWidth={false}>
									<div className="container_set">{t('Yêu cầu nạp tiền')}</div>
									<div className="form_set">
										<Box sx={{ minWidth: 600 }}>
											<TextField
												value={searched}
												onChange={(searchVal) =>
													requestSearch(searchVal.target.value)
												}
												placeholder={t("Tìm kiếm")}
												sx={{ marginBottom: "5px", paddingRight: "700px" }}
											/>
											<Table sx={{ width: 1200 }}>
												<TableHead>
													<TableRow>
														<TableCell>{t('Tài khoản')}</TableCell>
														<TableCell>{t('ID')}</TableCell>
														<TableCell>{t('Thông tin thanh toán')}</TableCell>
														<TableCell>{t('Số tiền')}</TableCell>
														<TableCell style={{textAlign: 'center'}}>{t('Trạng thái')}</TableCell>
														<TableCell style={{textAlign: 'center'}}>{t('Xử lý')}</TableCell>
														<TableCell>{t('Thời gian')}</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{data
														?.slice((pages - 1) * 10, (pages - 1) * 10 + 10)
														.map((item) => (
															<>
																<TableRow>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item?.user?.username}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item?.user?.iduser}
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{item.detail}
																	</TableCell>

																	<TableCell sx={{ fontWeight: "600" }}>
																		{Number(item.money).toLocaleString()} VNĐ
																	</TableCell>
																	{item?.status_payment === "Pending" ? (
																		<TableCell style={{textAlign: 'center'}}>
																			<Button type="text" color="warning">
																				{t(item?.status_payment)}
																			</Button>
																		</TableCell>
																	) : null}
																	{item?.status_payment === "Success" ? (
																		<TableCell style={{textAlign: 'center'}}>
																			<Button type="text" color="success">
																				{t(item?.status_payment)}
																			</Button>
																		</TableCell>
																	) : null}
																	{item?.status_payment === "Deny" ? (
																		<TableCell style={{textAlign: 'center'}}>
																			<Button type="text" color="error">
																				{t(item?.status_payment)}
																			</Button>
																		</TableCell>
																	) : null}
																	<TableCell style={{textAlign: 'center'}}>
																		<Button
																			onClick={() => {
																				const formData = {
																					id: item._id,
																					status: "Accept",
																				};
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/payment/update`,
																						formData
																					)
																					.then((res) => {
																						setLoad(false);
																					})
																					.catch((err) => setLoad(false));
																			}}
																			disabled={
																				item?.status_payment !== "Pending"
																			}
																		>
																			{t('Xác nhận')}
																		</Button>
																		<Button
																			onClick={() => {
																				const formData = {
																					id: item._id,
																					status: "Deny",
																				};
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/payment/update`,
																						formData
																					)
																					.then((res) => {
																						setLoad(false);
																					})
																					.catch((err) => setLoad(false));
																			}}
																			disabled={
																				item?.status_payment !== "Pending"
																			}
																		>
																			{t('Từ chối')}
																		</Button>
																	</TableCell>
																	<TableCell sx={{ fontWeight: "600" }}>
																		{formatDate(new Date(item?.createdAt))}
																	</TableCell>
																</TableRow>
															</>
														))}
												</TableBody>
											</Table>
										</Box>
									</div>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											pt: 3,
										}}
									>
										{data != null ? (
											<Pagination
												color="primary"
												count={Math.floor(data.length / 10) + 1}
												size="small"
												onChange={handleChange}
											/>
										) : null}
									</Box>
								</Container>
							)}
						</Box>
					}
					<ToastContainer />
				</DashboardLayout>
			</ThemeProvider>
		</>
	);
}
export default Add;
